  <template>
  <div>
    <v-row>
      <v-col>
        <picker
          :rules="required"
          :label="$t('t.DisputeReason')"
          class="mt-5"
          :disabled="statusWillCloseDispute"
          :clearable="true"
          :selected-id.sync="disputeReasonId"
          document-types="dispute-reasons"
        />
      </v-col>
      <v-col>
        <date-picker-menu
          v-model="targetDate"
          :label="$t('t.TargetDate')"
          :disabled="(document && document.daysResolutionLocked) || statusWillCloseDispute"
          :rules="required"
          :min="moment().format('YYYY-MM-DD')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import DocumentEditor from '@/mixins/document-editor'

export default {
  mixins: [DocumentEditor],
  components: {
    DatePickerMenu: () => import('@/components/date-picker-menu'),
    Picker: () => import('@/components/picker')
  },
  computed: {
    cacheType () {
      return DocumentEditor.CacheType.DisputeReasonDetail
    },
    id () {
      return this.dispute.disputeReasonId
    },
    disputeReasonId: {
      get: function () {
        return this.dispute.disputeReasonId
      },
      set: function (disputeReasonId) {
        this.$emit('update:dispute', Object.assign({}, this.dispute, { disputeReasonId }))
        this.$nextTick(() => {
          if (disputeReasonId) {
            this.$waitFor(() => this.document.id === disputeReasonId).then(() => {
              if (this.document.daysResolutionNumber) {
                this.targetDate = this.moment().add(this.document.daysResolutionNumber, 'days').format('YYYY-MM-DD')
              }
            })
            this.$emit('update:reason')
          }
        })
      }
    },
    required () {
      return [
        v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')
      ]
    },
    targetDate: {
      get: function () {
        return this.dispute.targetDate
      },
      set: function (targetDate) {
        this.$emit('update:dispute', Object.assign({}, this.dispute, { targetDate }))
      }
    }
  },
  props: {
    dispute: Object,
    statusWillCloseDispute: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.document-list
  height 500px
</style>
